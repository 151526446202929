// src/config/api.js

const REACT_APP_API_BASE_URL="https://ipenhportalapi.pensoft.co.ke/api"; //"https://localhost:7015/api" or  "https://ipenhportalapi.pensoft.co.ke/api",for development
const API_BASE_URL = REACT_APP_API_BASE_URL || "http://localhost:5284/api";

export const API_ENDPOINTS = {
  register_api: `${API_BASE_URL}/user/register`,
  login_api: `${API_BASE_URL}/user/login`,
  // To Add other API endpoints here
};

export default API_BASE_URL;
