//ipenh_frontend/src/pages/Register.jsx

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { message } from "antd";
import axios from "axios";
import API_BASE_URL, { API_ENDPOINTS } from "../config/api";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    provider: "",
    surname: "",
    firstName: "",
    otherNames: "",
    idType: "NationalID",
    idNumber: "",
    kraPin: "",
    email: "",
    phone: "",
    postalAddress: "",
    postalCode: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://ipenhportalapi.pensoft.co.ke/api/user/register",
        formData
      );
      message.success("Registration successful!");
      navigate("/staged-medical-claim");
    } catch (error) {
      message.error(error.response.data.message || "Registration failed");
    }
  };

  return (
    <div>
      <Header />
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 mt-3">
        {/* Increased max-width from max-w-md to max-w-2xl */}
        <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
          <h2 className="text-2xl font-semibold text-gray-900">Register</h2>
        </div>

        {/* Increased max-width from max-w-md to max-w-2xl */}
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-2xl">
          {/* Added max-w-full to ensure form takes full width of container */}
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 max-w-full">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Service Provider
                </label>
                <input
                  type="text"
                  name="provider"
                  placeholder="Enter the service provider(Hospital, Clinic, etc)"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={formData.provider}
                  onChange={(e) =>
                    setFormData({ ...formData, provider: e.target.value })
                  }
                />
              </div>
              <div>
                <input
                  type="text"
                  name="surname"
                  placeholder="Enter your surname"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="otherNames"
                  placeholder="Enter your other names"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <select
                  name="idType"
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}>
                  <option value="NationalID">National ID</option>
                  <option value="Passport">Passport</option>
                  <option value="Certificate">
                    Certificate of Corporation
                  </option>
                </select>
                <input
                  type="text"
                  name="idNumber"
                  placeholder="Type the number here"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div>
                <input
                  type="text"
                  name="kraPin"
                  placeholder="Enter your KRA PIN"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="07XXXXXXXX"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="postalAddress"
                  placeholder="Enter your postal address"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="postalCode"
                  placeholder="Postal code"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div>
                <input
                  type="password"
                  name="password"
                  placeholder="Type your password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  onChange={handleChange}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  REGISTER
                </button>
              </div>
            </form>

            <div className="mt-6 flex items-center justify-between">
              <div className="text-sm">
                Already have an account?{" "}
                <Link to="/login" className="text-blue-600 hover:text-blue-500">
                  Please Log In
                </Link>
              </div>
              <div className="text-sm">
                <Link
                  to="/privacy"
                  className="text-blue-600 hover:text-blue-500">
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Register;
