// src/pages/HelpCenter.jsx

import React from "react";
import ContactUs from "../components/ContactUs";
import UserManual from "../components/UserManual";
import { Spin } from "antd";

const HelpCenter = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <Spin spinning={false}>
        <h1 className="text-3xl font-bold text-center mb-8">Help Center</h1>
        <ContactUs />
        <UserManual />
      </Spin>
    </div>
  );
};

export default HelpCenter;
