// src/components/UserManual.jsx

import React from "react";

const UserManual = () => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-semibold mb-4">User Manual</h2>
      <p className="text-gray-700 mb-2">
        Welcome to the iPENH Healthcare Portal! Here’s a quick guide on how to
        use the system:
      </p>
      <h3 className="font-semibold mt-4">1. Logging In</h3>
      <p className="text-gray-700 mb-2">
        To access your account, enter your email and password on the login page.
        If you forget your password, click on the "Forgot Password?" link.
      </p>
      <h3 className="font-semibold mt-4">2. Submitting a Claim</h3>
      <p className="text-gray-700 mb-2">
        Navigate to the "Medical Claim" section. Fill in the required details,
        upload necessary documents, and click "Save Claim" to submit.
      </p>
      <h3 className="font-semibold mt-4">3. Viewing Claims</h3>
      <p className="text-gray-700 mb-2">
        You can view your submitted claims in the "Paid Claims" section. Here,
        you will find the status of each claim.
      </p>
      <h3 className="font-semibold mt-4">4. Contacting Support</h3>
      <p className="text-gray-700 mb-2">
        If you have any questions or need assistance, please refer to the
        "Contact Us" section for our support details.
      </p>
      <p className="text-gray-700 mt-4">Thank you for using our services!</p>
    </div>
  );
};

export default UserManual;
