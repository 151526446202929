// src/components/ContactUs.jsx

import React from "react";

const ContactUs = () => {
  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
      <p className="text-gray-700 mb-2">
        For any inquiries, please reach out to us:
      </p>
      <p className="text-gray-700">Email: support@ipenhealthcare.com</p>
      <p className="text-gray-700">Phone: +254 700 000 000</p>
      <p className="text-gray-700">Address: 123 Health St, Nairobi, Kenya</p>
      <p className="text-gray-700 mt-4">We are here to help you!</p>
    </div>
  );
};

export default ContactUs;
