// ipenh_frontend/src/pages/StagedMedicalClaim.jsx

import React, { useState } from "react";
import { message, Spin } from "antd";
import { Upload, Plus, Download, SaveAll, Save } from "lucide-react";
import axios from "axios";

const StagedMedicalClaim = () => {
  const [documents, setDocuments] = useState({
    claimForms: [],
    invoiceDocs: [],
  });

  const [invoices, setInvoices] = useState([
    {
      invoiceNo: "",
      invoiceDate: "",
      claimAmount: 0,
      invoiceDoc: null,
      claimDoc: null,
      lineItems: [],
    },
  ]);

  const [diagnosis, setDiagnosis] = useState([
    {
      icd10: "",
      description: "",
    },
  ]);

  const [claimData, setClaimData] = useState({
    memberNo: "",
    memberName: "",
    benefit: 0,
    invoiceDate: "",
    dateReceived: new Date().toISOString().split("T")[0],
    provider: "",
    invoices: [
      {
        invoiceNo: "",
        invoiceDate: "",
        claimAmount: 0,
        lineItems: [],
      },
    ],
    diagnosis: [
      {
        icd10: "",
        description: "",
      },
    ],
    document: [],
  });

  const [loading, setLoading] = useState(false);

  const handleInvoiceChange = (index, field, value) => {
    const updatedInvoices = [...invoices];
    updatedInvoices[index][field] = value;
    setInvoices(updatedInvoices);
  };

  const handleDiagnosisChange = (index, field, value) => {
    const updatedDiagnosis = [...diagnosis];
    updatedDiagnosis[index][field] = value;
    setDiagnosis(updatedDiagnosis);
  };

  const handleDownload = (doc) => {
    const linkSource = `data:application/pdf;base64,${doc.invoice}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = doc.claim;
    downloadLink.click();
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async (file, type) => {
    try {
      const base64File = await convertFileToBase64(file);
      const newDoc = {
        invoice: base64File,
        claim: file.name,
      };

      setDocuments((prev) => ({
        ...prev,
        [type]: [...prev[type], newDoc],
      }));

      setClaimData((prev) => ({
        ...prev,
        document: [...prev.document, newDoc],
      }));

      message.success("File added successfully");
    } catch (error) {
      console.error("File Processing Error:", error);
      message.error("Failed to process file: " + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation logic
    if (!claimData.memberNo || !claimData.memberName || !claimData.benefit) {
      message.error("Please fill in all required fields");
      return;
    }

    if (
      documents.claimForms.length === 0 &&
      documents.invoiceDocs.length === 0
    ) {
      message.error("Please add at least one document before submitting");
      return;
    }

    try {
      setLoading(true);

      const finalData = {
        ...claimData,
        document: [...documents.claimForms, ...documents.invoiceDocs],
      };

      const response = await axios({
        method: "post",
        url: "https://ipenhapi.pensoft.co.ke/api/MedService/ReportClaim",
        headers: {
          "Content-Type": "application/json",
          UserID: "liaison",
          ApiKey: "Test2023!",
        },
        data: finalData,
      });

      // Axios automatically throws errors for non-2xx responses
      // and parses JSON responses, so we can directly use response.data
      handleSuccessResponse(response.data);
    } catch (error) {
      if (error.response) {
        // The server responded with a status code outside of 2xx
        handleErrorResponse({
          message: error.response.data?.message || error.response.statusText,
          data: error.response.data,
        });
      } else if (error.request) {
        // The request was made but no response received
        handleErrorResponse({
          message: "No response received from server",
          data: error.request,
        });
      } else {
        // Error in request setup
        handleErrorResponse({
          message: "Error setting up the request",
          data: error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessResponse = (result) => {
    if (result.success) {
      message.success(`Claim saved successfully. Claim ID: ${result.claimId}`);
      // Reset form
      setClaimData({
        memberNo: "",
        memberName: "",
        benefit: "",
        invoiceDate: "",
        dateReceived: new Date().toISOString().split("T")[0],
        provider: "",
        invoices: [
          {
            invoiceNo: "",
            invoiceDate: "",
            claimAmount: 0,
            lineItems: [],
          },
        ],
        diagnosis: [
          {
            icd10: "",
            description: "",
          },
        ],
        document: [],
      });
      setDocuments({ claimForms: [], invoiceDocs: [] });
    } else {
      message.error(result.data?.join(", ") || "Failed to save claim");
    }
  };

  const handleErrorResponse = (error) => {
    console.error("API Error:", error);

    let errorMessage = "An unexpected error occurred";
    if (error.response && error.response.data) {
      if (Array.isArray(error.response.data)) {
        errorMessage = error.response.data.join(", ");
      } else if (typeof error.response.data === "string") {
        errorMessage = error.response.data;
      } else if (
        typeof error.response.data === "object" &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
    } else if (error.message) {
      errorMessage = error.message;
    }

    message.error(`Failed to save claim: ${errorMessage}`);
  };
  return (
    <div className="bg-white rounded-lg shadow">
      <Spin spinning={loading}>
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl text-center font-semibold">
              Medical Claim
            </h2>
            {/* <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center">
              <Plus className="w-4 h-4 mr-2" />
              Save Claim
            </button> */}
          </div>
          {/* Divider */}
          <hr className="my-4 border-t border-gray-300" />

          {/* Claim Details Section */}
          <div className="mb-8">
            <h3 className="text-lg font-extrabold mb-4 text-left">
              Claim Details
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Member No
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={claimData.memberNo}
                  onChange={(e) =>
                    setClaimData((prev) => ({
                      ...prev,
                      memberNo: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Service Provider
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={claimData.provider}
                  onChange={(e) =>
                    setClaimData((prev) => ({
                      ...prev,
                      provider: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Member Name
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={claimData.memberName}
                  onChange={(e) =>
                    setClaimData((prev) => ({
                      ...prev,
                      memberName: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Invoice Date
                </label>
                <input
                  type="date"
                  className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={claimData.invoiceDate}
                  onChange={(e) =>
                    setClaimData((prev) => ({
                      ...prev,
                      invoiceDate: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Benefit
                </label>
                <select
                  className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={claimData.benefit}
                  onChange={(e) =>
                    setClaimData((prev) => ({
                      ...prev,
                      benefit: e.target.value,
                    }))
                  }>
                  <option value="">Select benefit</option>
                  <option value="11">DENTAL</option>
                  <option value="36">DENTAL ILLNESS</option>
                  <option value="22">
                    EMERGENCY EVACUATION WITHIN KENYA(ON PREAUTHORIZATION)
                  </option>
                  <option value="18">FAMILY PLANNING</option>
                  <option value="38">First Emergency Caesarean</option>
                  <option value="19">GENERAL HEALTH CHECK-UP</option>
                  <option value="30">IN PATEINT COVID-19</option>
                  <option value="23">
                    IN PATIENT ACCIDENTAL DENTAL AND OPTICAL/OPHTHALMOLOGY
                    TREATMENT
                  </option>
                  <option value="34">
                    IN PATIENT ACUTE ILLNESS AND ACCIDENTS
                  </option>
                  <option value="16">
                    IN PATIENT CONGENITAL/CHILDBIRTH COVER/NEO NATAL
                    CONDITIONS/PREMATURITY EXPENSES
                  </option>
                  <option value="26">IN PATIENT NON ACCIDENTAL DENTAL</option>
                  <option value="27">
                    IN PATIENT NON ACCIDENTAL OPTICAL/OPTHALMOLOGY
                  </option>
                  <option value="15">
                    IN PATIENT PRE-EXISTING/CHRONIC/HIV/AIDS
                  </option>
                  <option value="25">
                    IN PATIENT PSYCHIATRY AND PSYCHOTHERAPY TREATMENT
                  </option>
                  <option value="29">
                    INPATIENT MATERNITY NORMAL/SUBSEQUENT AND ELECTIVE
                    CS/COMPLICATIONS
                  </option>
                  <option value="14">INPATIENT OVERALL</option>
                  <option value="24">
                    IN-PATIENT PRE-EXISTING/CHRONIC/HIV/AIDS CONDITIONS/NEWLY
                    DIAGNOSED CHRONIC ILLNESS
                  </option>
                  <option value="21">KEPI AND BABY FRIENDLY</option>
                  <option value="17">LAST EXPENSE</option>
                  <option value="20">
                    MATERNITY NORMAL/SUBSEQUENT AND ELECTIVE CS/COMPLICATIONS
                  </option>
                  <option value="12">OPTICAL</option>
                  <option value="13">OPTICAL FRAMES</option>
                  <option value="39">Optical Illness</option>
                  <option value="31">
                    OUT PATIENT PRE-EXISTING AND CHRONIC AND HIV/AIDS CONDITIONS
                  </option>
                  <option value="37">
                    OUTPATIENT ANTENATAL AND POSTNATAL CARE
                  </option>
                  <option value="33">OUTPATIENT COVID-19</option>
                  <option value="9">OUTPATIENT OVERALL</option>
                  <option value="40">Post Hospitalization</option>
                  <option value="28">PRESCRIBED EXTERNAL APPLIANCES</option>
                  <option value="32">PRIVATE VACCINES</option>
                </select>
              </div>
            </div>
          </div>

          {/* Divider */}
          <hr className="my-4 border-t border-gray-300" />

          {/* Invoices Section */}
          <div className="mb-8">
            <h3 className="text-lg font-extrabold mb-4 text-left">
              Invoices
            </h3>
            <div className="space-y-4">
              {invoices.map((invoice, index) => (
                <div key={index} className="border rounded-lg p-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Invoice Number
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        value={invoice.invoiceNo}
                        onChange={(e) =>
                          handleInvoiceChange(
                            index,
                            "invoiceNo",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Invoice Date
                      </label>
                      <input
                        type="date"
                        className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        value={invoice.invoiceDate}
                        onChange={(e) =>
                          handleInvoiceChange(
                            index,
                            "invoiceDate",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Invoice Amount
                      </label>
                      <input
                        type="number"
                        className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        value={invoice.claimAmount}
                        onChange={(e) =>
                          handleInvoiceChange(
                            index,
                            "claimAmount",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  {/* Document Upload Section */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
                      <div className="text-center">
                        <Upload className="mx-auto h-8 w-8 text-gray-400" />
                        <div className="mt-2">
                          <button
                            type="button"
                            className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() =>
                              document
                                .getElementById(`invoice-upload-${index}`)
                                .click()
                            }>
                            Upload Invoice
                          </button>
                          <input
                            id={`invoice-upload-${index}`}
                            type="file"
                            className="hidden"
                            onChange={(e) =>
                              handleFileUpload(
                                e.target.files[0],
                                "invoiceDocs",
                                index
                              )
                            }
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png, .csv, .xls, .xlsx, .txt, .rtf, .ppt, .pptx"
                          />
                        </div>
                        {invoice.invoiceDoc && (
                          <div className="mt-2 flex items-center justify-between p-2 bg-gray-50 rounded">
                            <span className="text-sm text-gray-600">
                              {invoice.invoiceDoc.claim}
                            </span>
                            <button
                              onClick={() => handleDownload(invoice.invoiceDoc)}
                              className="text-blue-600 hover:text-blue-800">
                              <Download className="h-4 w-4" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
                      <div className="text-center">
                        <Upload className="mx-auto h-8 w-8 text-gray-400" />
                        <div className="mt-2">
                          <button
                            type="button"
                            className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() =>
                              document
                                .getElementById(`claim-form-upload-${index}`)
                                .click()
                            }>
                            Upload Claim Form
                          </button>
                          <input
                            id={`claim-form-upload-${index}`}
                            type="file"
                            className="hidden"
                            onChange={(e) =>
                              handleFileUpload(
                                e.target.files[0],
                                "claimForms",
                                index
                              )
                            }
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png, .csv, .xls, .xlsx, .txt, .rtf, .ppt, .pptx"
                          />
                        </div>
                        {invoice.claimDoc && (
                          <div className="mt-2 flex items-center justify-between p-2 bg-gray-50 rounded">
                            <span className="text-sm text-gray-600">
                              {invoice.claimDoc.claim}
                            </span>
                            <button
                              onClick={() => handleDownload(invoice.claimDoc)}
                              className="text-blue-600 hover:text-blue-800">
                              <Download className="h-4 w-4" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 flex items-center"
                onClick={() =>
                  setInvoices([
                    ...invoices,
                    {
                      invoiceNo: "",
                      invoiceDate: "",
                      claimAmount: 0,
                      invoiceDoc: null,
                      claimDoc: null,
                      lineItems: [],
                    },
                  ])
                }>
                <Plus className="w-4 h-4 mr-2" />
                Add Invoice
              </button>
            </div>
          </div>

          {/* Divider */}
          <hr className="my-4 border-t border-gray-300" />

          {/* Diagnosis Section */}
          <div className="mb-8">
            <h3 className="text-lg font-extrabold mb-4 text-left">
              Claim Diagnosis
            </h3>
            <div className="space-y-4">
              {diagnosis.map((diag, index) => (
                <div key={index} className="border rounded-lg p-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        ICD-10 Code
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        value={diag.icd10}
                        onChange={(e) =>
                          handleDiagnosisChange(index, "icd10", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        value={diag.description}
                        onChange={(e) =>
                          handleDiagnosisChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 flex items-center"
                onClick={() =>
                  setDiagnosis([...diagnosis, { icd10: "", description: "" }])
                }>
                <Plus className="w-4 h-4 mr-2" />
                Add Diagnosis
              </button>
            </div>
          </div>
          {/* Divider */}
          <hr className="my-2 border-t border-gray-300" />
        </div>
        {/* Save Claim Button */}
        <div className="flex justify-center mb-2">
          <button
            onClick={handleSubmit}
            className="px-4 py-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center">
            <SaveAll className="w-4 h-4 mr-2" />
            Save Claim
          </button>
        </div>
        {/* Divider */}
        <hr className="my-2 border-t border-gray-300" />
      </Spin>
    </div>
  );
};
export default StagedMedicalClaim;
