// src/components/Layout.jsx
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-1">
        {/* Sidebar */}
        <div className="hidden md:block fixed top-0 left-0 max-h-fit z-10 w-64 bg-white shadow-lg">
          <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
        </div>

        {/* Main content */}
        <main className="flex-1 p-4 md:p-6 pt-20 md:ml-64">
          <div className="max-w-7xl mx-auto mt-16">
            <Outlet />
          </div>
        </main>
      </div>
      {/* <Footer className="mt-auto md:ml-64" />{" "} */}
      {/* Add left margin to align with sidebar */}
    </div>
  );
};

export default Layout;
